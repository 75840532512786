import { make } from 'vuex-pathify';
import { buildProcessedEvent } from '@/helpers/global/event-helpers';
import { isDemoInstance } from '~/util/utility-functions';
import c from './common/index';
import {
  RANGE_CUSTOM,
  COMPARE_RANGE_PREV_DAY,
  DEFAULT_CONVERSION_WINDOW,
  DEFAULT_CONVERSION_WINDOW_DISPLAY,
} from '~/constant';

let startDate = new Date(new Date().setDate(new Date().getDate() - 30));
let endDate = new Date(new Date().setDate(new Date().getDate() - 1));
let compareStartDate = new Date(new Date().setDate(new Date().getDate() - 30));
let compareEndDate = new Date(new Date().setDate(new Date().getDate() - 2));

if (isDemoInstance()) {
  startDate = new Date(2022, 2, 9);
  endDate = new Date(2022, 3, 15);
  compareStartDate = new Date(2022, 2, 9);
  compareEndDate = new Date(2022, 3, 14);
}

const dates = {
  startDate,
  endDate,
  compareWith: false,
  compareStartDate,
  compareEndDate,
  dateRangeOption: RANGE_CUSTOM,
  dateCompareOption: COMPARE_RANGE_PREV_DAY,
};
const filters = {
  campaignOptions: [],
  adGroupOptions: [],
  mediaTypeOptions: [],
  audienceOptions: [],
  creativeOptions: [],
  publisherOptions: [],
  keywordOptions: [],
  pixelOptions: [],
  conversionWindow: DEFAULT_CONVERSION_WINDOW,
  conversionWindowDisplay: DEFAULT_CONVERSION_WINDOW_DISPLAY,
  conversionWindowUnit: 'DAYS',
  ioOptions: [],
  attribution: 'full',
  showUniques: false,
  methodology: 'last_touch',
  eventOptions: [],
  incReachMetricsData: {},
  attributionOptions: [
    {
      id: 'full',
      text: 'FULL CONVERSIONS',
      active: true,
      infoText:
        'Full conversion methodology will count a full conversion as long as the conversion occurred within the set date range, and assign credit regardless of when the impression(s) that drove the conversion occurred, even if they occurred outside of the selected date range.',
    },
    {
      id: 'partial',
      text: 'PARTIAL CONV',
      active: false,
      infoText:
        'Partial conversion methodology will calculate either a partial or full conversion, depending on your selected attribution methodology and how many impressions that drove the conversion also occurred within the selected date range.',
    },
  ],
  totalOrUniqueRadioOptions: [
    {
      text: 'TOTAL',
      active: true,
      disabled: false,
    },
    {
      text: 'UNIQUE',
      active: false,
      infoText: 'Shows only unique conversion counts, will not report repeat converters',
      disabled: false,
    },
  ],
  methodologyOptions: [
    {
      id: 'last_touch',
      name: 'Last Touch',
      infoText:
        'Last touch - Full credit will be applied to the variables (i.e. publisher, creative, etc) of the last impression served prior to the event.',
    },
    {
      id: 'first_touch',
      name: 'First Touch',
      infoText:
        'First Touch - Full credit will be applied to the variables (i.e. publisher, creative, etc) of the first impression served prior to the event.',
    },
    {
      id: 'linear',
      name: 'Linear',
      infoText:
        'Linear - Credit will evenly applied across the variables (i.e. publisher, creative, etc) of all of the impressions served prior to the event.',
    },
    {
      id: 'time_decay',
      name: 'Time Decay',
      infoText:
        'Time Decay - Credit will be weighted more heavily toward variables (i.e. publisher, creative, etc) of impressions that served prior to the event as the impression timestamps approach the event timestamp.',
    },
  ],
};
const pixelDates = {
  startDate: new Date(new Date().setDate(new Date().getDate() - 29)),
  endDate: new Date(),
  dateRangeOption: 7,
};
const state = {
  dates,
  filters,
  filtersApplied: false,
  filtersAppliedLoading: false,
  selectedDashboardTab: 'adgroup',
  preventDatesLoading: false,
  isConversionDisabled: false,
  pixelDates,
};

const getters = {
  GET_SELECTED_MEDIATYPES(st) {
    return st.filters.mediaTypeOptions.filter((f) => f.checked).map((m) => m.value);
  },
  GET_SELECTED_CREATIVES(st) {
    return st.filters.creativeOptions.filter((f) => f.checked).map((m) => m.key);
  },
  GET_SELECTED_PUBLISHERS(st) {
    return st.filters.publisherOptions.filter((f) => f.checked).map((m) => m.key);
  },
  GET_SELECTED_AUDIENCES(st) {
    return st.filters.audienceOptions.filter((f) => f.checked).map((m) => m.value);
  },
  GET_SELECTED_IOS(st) {
    return st.filters.ioOptions.filter((f) => f.checked).map((m) => m.value);
  },
  GET_SELECTED_IO_IDS(st) {
    const { ioOptions } = st.filters;
    const selectedIoIds = st.filters.ioOptions.filter((f) => f.checked);
    const { currentUserId } = c.state;
    // hack for shaun@aeonmarketing.com.au (Acer)
    if (currentUserId !== 1081 && ioOptions.length === selectedIoIds.length) {
      return [];
    }
    return st.filters.ioOptions.filter((f) => f.checked).map((m) => m.key);
  },
  GET_SELECTED_KEYWORDS(st) {
    return st.filters.keywordOptions.filter((f) => f.checked).map((m) => m.value);
  },
  GET_SELECTED_PIXEL_IDS(st) {
    const { pixelOptions } = st.filters;
    const selectedPixels = st.filters.pixelOptions.filter((f) => f.checked);
    if (pixelOptions.length === selectedPixels.length) {
      return [];
    }
    return selectedPixels.map((m) => m.key);
  },
  GET_SELECTED_CAMPAIGNS(st) {
    return st.filters.campaignOptions.filter((f) => f.checked).map((m) => m.value);
  },
  GET_SELECTED_CAMPAIGN_IDS(st) {
    const { campaignOptions } = st.filters;
    const selectedCampaignIds = st.filters.campaignOptions.filter((f) => f.checked);
    if (campaignOptions.length === selectedCampaignIds.length) {
      return [];
    }
    return st.filters.campaignOptions.filter((f) => f.checked).map((m) => m.key);
  },
  GET_SELECTED_AD_GROUP_STR(st) {
    const { adGroupOptions } = st.filters;
    if (adGroupOptions.length === 0) {
      return '';
    }
    const selectedAdGroupOptions = adGroupOptions.filter((f) => f.checked);
    if (!(selectedAdGroupOptions.length === adGroupOptions.length)) {
      const queryStrArray = [];
      selectedAdGroupOptions.forEach((adGroup) => {
        const data = adGroup?.liData !== undefined ? adGroup.liData : adGroup.adreadyIds;
        data.forEach((lid) => {
          if (lid.adreadyId && lid.flightStartDate) {
            queryStrArray.push(`${lid.adreadyId}|${lid.flightStartDate}`);
          }
        });
      });
      return queryStrArray.join(',');
    }
    return '';
  },
  GET_SELECTED_EVENT(st) {
    return buildProcessedEvent(st.filters.eventOptions);
  },
};

const actions = {
  resetEventOptions: ({ commit }) => {
    commit('RESET_EVENT_OPTIONS');
  },
  resetAdvanceFilters: ({ commit }) => {
    commit('RESET_ADVANCE_FILTERS');
  },
  resetDates: ({ commit }) => {
    commit('SET_RESET_DATES');
  },
  deSelectAdvanceFilters: ({ commit }) => {
    commit('DESELECT_ADVANCE_FILTERS');
  },
  setIncReachMetric: ({ commit }, payload) => {
    commit('SET_INC_REACH_METRIC_DATA', payload);
  },
};

const mutations = {
  ...make.mutations(state),
  SET_RESET_DATES(st) {
    if (!st) {
      return;
    }
    st.dates = dates;
  },
  SET_RESET_FILTERS(st) {
    if (!st) {
      return;
    }
    st.filters = filters;
  },
  RESET_EVENT_OPTIONS(st) {
    st.filters.totalOrUniqueRadioOptions = filters.totalOrUniqueRadioOptions;
  },
  SET_UPDATE_FILTERS_DATA(st, payload = {}) {
    if (!st) {
      return;
    }
    st.filters = { ...st.filters, ...payload };
  },
  SET_DATES(st, payload = {}) {
    if (!st) {
      return;
    }
    st.dates = payload;
  },
  RESET_ADVANCE_FILTERS(st) {
    if (!st || !st.filters) {
      return;
    }
    st.filters.mediaTypeOptions = [];
    st.filters.audienceOptions = [];
    st.filters.creativeOptions = [];
    st.filters.publisherOptions = [];
    st.filters.keywordOptions = [];
    st.filters.pixelOptions = [];
    st.filters.conversionWindow = DEFAULT_CONVERSION_WINDOW;
  },
  DESELECT_ADVANCE_FILTERS(st) {
    if (!st || !st.filters) {
      return;
    }
    st.filters.keywordOptions
      .filter((f) => f.checked)
      .forEach((m) => {
        m.checked = false;
      });
    st.filters.creativeOptions
      .filter((f) => f.checked)
      .forEach((m) => {
        m.checked = false;
      });
  },
  SET_INC_REACH_METRIC_DATA(st, payload) {
    st.incReachMetricsData = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
